<template>
    <div style="background-color: white;">
        <div class="w-4/5" style="margin: 0 auto;">
            <div style="display: flex;align-items: center;justify-content: flex-start;margin-top: 30px;">
                <img src="../assets/arrow_left.png" style="width: 24px;height: 24px;cursor: pointer;"
                    @click="goBack">
                <p style="color: #333;font-size: 18px;font-weight: bold;">
                    Please select the EAE Pass you wish to use.
                </p>
            </div>
            <el-table :data="dataList" style="font-size: 12px;padding: 20px;box-sizing: border-box;"
                empty-text="No Data" :highlight-current-row="false" :highlight-selection-row="false"
                :header-cell-class-name="'custom-head-cell'" :cell-style="tableCellstyle"
                :row-class-name="'custom-row-bg'">
                <el-table-column prop="pass.name" label="Pass Name" width="280px"></el-table-column>
                <el-table-column prop="" label="Expiration Date" width="160px">
                    <template slot-scope="scope">
                        {{ formatrExpirationDate(scope.row) }}
                    </template>
                </el-table-column>
                <el-table-column prop="pass.type" label="Pass Type" width="120px"></el-table-column>
                <el-table-column prop="" label="Redemption Records">
                    <template slot-scope="scope">
                        <p v-html="formatRecords(scope.row)"></p>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="Operation" width="200px">
                    <template slot-scope="scope">
                        <el-button type="primary" style="width: 100px;" size="small"
                            @click="confirmVerify(scope.row)">Select</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import {
    verifyPass,
    getStudentValidPasses
} from '../api/index'
import '../assets/common/font.css'
export default ({
    name: 'index',
    data() {
        return {
            application_id: this.$route.query.application_id,
            dataList: [],
            project_name: this.$route.query.project_name
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        formatRecords(order) {
            let pass = order.pass
            let result = ''
            if (pass.records && pass.records.length > 0) {
                let index = 0
                for (let record of pass.records) {
                    index++
                    let create_time = record.create_time
                    let project = record.project.project_ename
                    result += project + ', ' + this.$dayjs(create_time * 1000).format('MMMM DD, YYYY')
                    if (index != pass.records.length) {
                        result += '<br>'
                    }
                }
            }
            return result
        },

        formatrExpirationDate(order) {
            if (order.pass.expired_type == 'expired_by_ddl') {
                let expired_value = order.pass.expired_value
                return this.$dayjs(expired_value).format('MMMM DD, YYYY')
            }
            let expired_time = order.pay_time + order.pass.expired_value * 24 * 60 * 60
            return this.$dayjs(expired_time * 1000).format('MMMM DD, YYYY')
        },

        fetchData() {
            getStudentValidPasses(this.application_id).then((res) => {
                this.dataList = res.data
            })
        },
        goBack() {
            this.$router.go(-1)
        },
        confirmVerify(row) {
            this.$confirm('Do you confirm to use the ' + row.pass.name +' for the ' + this.project_name + '?', 'Pass Redemption', {
                confirmButtonText: 'Confirm',
                cancelButtomText: 'Cancel',
                callback: action => {
                    if (action == 'confirm') {
                        verifyPass(row.pass_id, this.application_id).then((res) => {
                            let result = res.data
                            if (result.code == 0) {
                                this.$message.success('Pass Redemption Successful.')
                                this.$router.go(-2)
                            } else {
                                this.$message.warning(result.msg)
                            }
                        })
                    }
                }
            })
        },
        tableCellstyle() {
            return 'background:  #FAE3E4;border:1px solid white;text-align:center; font-family: GravityBold;'
        },
    }
})
</script>

<style scoped lang="scss">
/deep/ .el-table .custom-row-bg {
    background: #FAE3E4;
}

/deep/ .el-table .custom-head-cell {
    background: var(--c-primary);
    color: white;
    text-align: center;
    box-sizing: border-box;
    font-family: GravityBold;
}
</style>